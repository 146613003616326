import React from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

const Contact = (props) => {
  const { t } = useTranslation();
  return (
    <div className="composition--contact">
      <div className="container">
        <div className="grid-noGutter">
          <div className="col-6_sm-12 contents">
            <h5 className="subheader first">{t('CONTACT__VISIT_US')}</h5>
            <div className="tagline-with-icon">
              <img src={require('../../assets/images/icon__map.svg')} alt={'not found'} />
              <p className="tagline">{t('CONTACT__VISIT_ADDRESS')}</p>
            </div>
            <p>{t('CONTACT__VISIT_DESCRIPTION')}</p>
            <h5 className="subheader">{t('CONTACT__CONTACT_US')}</h5>
            <div className="tagline-with-icon">
              <img src={require('../../assets/images/icon__phone.svg')} alt={'not found'} />
              <p className="tagline">{t('CONTACT__CONTACT_TEL')}</p>
            </div>
            <div className="tagline-with-icon">
              <img src={require('../../assets/images/icon__email.svg')} alt={'not found'} />
              <p className="tagline">{t('CONTACT__CONTACT_EMAIL')}</p>
            </div>
            <p>{t('CONTACT__CONTACT_DESCRIPTION')}</p>
          </div>
          <div className="col-6_sm-12 map">
            <iframe 
              title="access-map"
              className="map-iframe"
              src={
                props.language === 'ja'
                ? "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3236.796890040966!2d139.6104744158192!3d35.780365296405385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc0e85ee61939d400!2z54m55a6a5Zu956uL56CU56m26ZaL55m65rOV5Lq6IOeQhuWMluWtpueglOeptuaJgA!5e0!3m2!1sja!2sus!4v1596770709753!5m2!1sja!2sus"
                : "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3236.796890040966!2d139.6104744158192!3d35.780365296405385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc0e85ee61939d400!2z54m55a6a5Zu956uL56CU56m26ZaL55m65rOV5Lq6IOeQhuWMluWtpueglOeptuaJgA!5e0!3m2!1sen!2sus!4v1596770709753!5m2!1sen!2sus"
              } 
              frameBorder="0"
              allowFullScreen=""
              aria-hidden="false"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Contact.propTypes = {
  
}


export default Contact;
