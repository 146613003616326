import React from 'react';
import { withTranslation } from 'react-i18next';

import './style.scss';

import Project from '../../components/Project';

const Projects = (props) => {
  const { t } = props;
  return (
    <div className="composition--projects">
      <div className="container">
        <div className="grid">
          <div className="col-12 section-title">
            <h3 className="title">{t('PROJECTS__SECTION_TITLE')}</h3>
            <p className="subtitle">{t('PROJECTS__SECTION_SUBTITLE')}</p>
          </div>
          <div className="col-4_md-12">
            <Project
              header={t('PROJECTS__PROJECT_1_TITLE')}
              description={t('PROJECTS__PROJECT_1_DESCRIPTION')}
              thumbnailNumber={1}
            />
          </div>
          <div className="col-4_md-12">
            <Project
              header={t('PROJECTS__PROJECT_2_TITLE')}
              description={t('PROJECTS__PROJECT_2_DESCRIPTION')}
              thumbnailNumber={2}
            />
          </div>
          <div className="col-4_md-12">
            <Project
              header={t('PROJECTS__PROJECT_3_TITLE')}
              description={t('PROJECTS__PROJECT_3_DESCRIPTION')}
              thumbnailNumber={3}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Projects.propTypes = {
  
}


export default withTranslation()(Projects);
