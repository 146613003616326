import React from 'react';
import './style.scss';

const Project = (props) => {
  const { 
    header,
    description,
    thumbnailNumber
  } = props;
  
  return (
    <div className="component--project" onClick={() => {}}>    
      <div className="image-container" style={{backgroundImage: getThumbnailUrl(thumbnailNumber)}}></div>
      <div className="captions">
        <p className="header">{header}</p>
        <p>{description}</p>
      </div>
    </div>
  );
};

const getThumbnailUrl = (id) => {
  switch (id) {
    case 1:
      return `url("${require('../../assets/images/bg__project1.jpg')}")`;
    case 2:
      return `url("${require('../../assets/images/bg__project2.jpg')}")`;
    case 3:
      return `url("${require('../../assets/images/bg__project3.jpg')}")`;
    default:
      return '';
  }
}

export default Project;
