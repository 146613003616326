import {
    FETCH_CMS__INITIATE,
    FETCH_CMS__SUCCESS,
    FETCH_CMS__ERROR
  } from './constants';
  
  export function fetchCMS__initiate() {
    return {
      type: FETCH_CMS__INITIATE
    };
  }

  export function fetchCMS__success(data) {
    return {
      type: FETCH_CMS__SUCCESS,
      data
    };
  }
  
  export function fetchCMS__error(error) {
    return {
      type: FETCH_CMS__ERROR,
      error
    };
  }