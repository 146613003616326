import { FETCH_CMS__PUBLICATIONS__UPDATE } from './constants';

const initialState = {
  data: null
};

function publicationsReducer(state = initialState, action) {
  switch(action.type) {
    case FETCH_CMS__PUBLICATIONS__UPDATE:
      return {
        ...state,
        data: action.data,
      };
    
    default:
      return state;
  }
}

export default publicationsReducer;