import React from 'react';
import './style.scss';

const ImageCollection = (props) => {
  const { imageGroup } = props;
  const largeImages = imageGroup.filter((image) => image.isLarge) || [];
  const smallImages = imageGroup.filter((image) => !image.isLarge) || [];
  const images = [...largeImages, ...smallImages];
  let el;
  if (images.length === 6) {
    el = 
    <div className="image-collection-wrapper">
      <div className="collection--medium">
        <div className="image-container" style={{backgroundImage: `url(${images[0].imgUrl})`}} />
        <div className="collection--medium flex">
          <div className="image-container" style={{backgroundImage: `url(${images[2].imgUrl})`}} />
          <div className="image-container" style={{backgroundImage: `url(${images[3].imgUrl})`}} />
        </div>
      </div>
      <div className="collection--medium">
        <div className="collection--medium flex">
          <div className="image-container" style={{backgroundImage: `url(${images[4].imgUrl})`}} />
          <div className="image-container" style={{backgroundImage: `url(${images[5].imgUrl})`}} />
        </div>
       <div className="image-container" style={{backgroundImage: `url(${images[1].imgUrl})`}} />
      </div>
    </div>;
  } else if (images.length === 4) {
    el = 
    <div className="image-collection-wrapper">
      <div className="collection--large">
        <div className="image-container" style={{backgroundImage: `url(${images[0].imgUrl})`}} />
      </div>
      <div className="collection--small">
        <div className="image-container" style={{backgroundImage: `url(${images[1].imgUrl})`}} />
        <div className="image-container" style={{backgroundImage: `url(${images[2].imgUrl})`}} />
        <div className="image-container" style={{backgroundImage: `url(${images[3].imgUrl})`}} />
      </div>
    </div>;
  }
  return (
    <div className="component--image-collection">
      {el}
    </div>
  );
};

export default ImageCollection;
