import React from 'react';
import { useTranslation } from 'react-i18next';
import SwiperCore, { Navigation } from 'swiper';

import 'swiper/swiper.scss';
import './style.scss';

import Publication from '../../components/Publication';

SwiperCore.use([Navigation]);

const Publications = (props) => {
  const { publications, language } = props;

  const updateCaptionHeight = (h) => {
    console.log(h, 'height');
  }

  const { t } = useTranslation();
  return (
    <div className="composition--publications">
      <div className="container">
        <div className="grid">
          <div className="col-12 section-title">
          <h3>{t('PUBLICATIONS__SECTION_TITLE')}</h3>
          </div>
          <div className="col-12 publication-container grid">
            {publications &&
              publications.map((publication, index) => {
                return (
                  <div className="col-6_xs-12 publications" key={`publication-swiper-slide-${index}`}>
                    <Publication
                      header={language === 'ja' ? publication.fields.title_ja : publication.fields.title}
                      description={language === 'ja' ? publication.fields.description_ja : publication.fields.description}
                      thumbnailUrl={publication.fields.thumbnail[0].url}
                      link={publication.fields.link}
                      updateCaptionHeight={(h) => updateCaptionHeight(h)}
                    />
                  </div>
                );
              })
            }
          </div>
          <div className="col-12 see-all">
            <a target="_blank" rel="noopener noreferrer" href="https://scholar.google.com/citations?user=N2kSGPgAAAAJ&hl=en">{t('PUBLICATIONS__SEE_ALL')}</a>
          </div>
        </div>
      </div>
    </div>
  );
};

Publications.propTypes = {
  
}


export default Publications;
