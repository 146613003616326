import { call, put, takeLatest } from 'redux-saga/effects';
import request from '../../utils/request';
import { store } from '../../index';
import * as ApiActions from '../api/actions';

import * as HighlightsActions from '../highlights/actions';
import * as PublicationActions from '../publications/actions';
import * as MembersActions from '../members/actions';
import * as GalleryActions from '../gallery/actions';
import * as OpeningsActions from '../openings/actions';

const url = process.env.NODE_ENV === 'development' ? 'http://localhost:4200/cms' : '/api/cms';

export function* fetchCMS() {
    try {
        const data = yield call(request, url); 
        yield put(store.dispatch(HighlightsActions.fetchCMS__Highlights__update(data.highlights?.records || {})));
        yield put(store.dispatch(PublicationActions.fetchCMS__Publications__update(data.publications?.records || {})));
        yield put(store.dispatch(MembersActions.fetchCMS__Members__update(data.members?.records || {})));
        yield put(store.dispatch(GalleryActions.fetchCMS__Gallery__update(data.gallery?.records || {})));
        yield put(store.dispatch(OpeningsActions.fetchCMS__Openings__update(data.openings?.records || {})));
        yield put(store.dispatch(ApiActions.fetchCMS__success(data)));
    } catch (error) {
        yield put(store.dispatch(ApiActions.fetchCMS__error(error)));
    }
}

function* fetchCMSSaga() {
    yield takeLatest(ApiActions.fetchCMS__initiate().type, fetchCMS);
}

export default fetchCMSSaga;
