export default {
  translation: {
    // Navigation
    NAVIGATION__TEAM_NAME : 'Laboratory for Glia-Neuron Circuit Dynamics',
    NAVIGATION__AFFILIATION: 'Jun Nagai Lab at RIKEN',
    NAVIGATION__ITEM__VISION: 'Vision',
    NAVIGATION__ITEM__PUBLICATIONS: 'Publications',
    NAVIGATION__ITEM__OUR_LAB: 'Our Lab',
    NAVIGATION__ITEM__JOIN_US: 'Join Us',
    NAVIGATION__ITEM__CONTACT: 'Contact',
    NAVIGATION__ITEM__LANGUAGE: '日本語',

    // Header
    HEADER__TAGLINE: 'Astrocytes: \nStar Cells in Our Inner Universes',

    // Highlights
    HIGHLIGHTS__SECTION_TITLE: 'Highlights',

    // Vision
    VISION__SECTION_TITLE: 'Our Vision',
    VISION__DESCRIPTION_1: 'In our inner universes; i.e. the brain',
    VISION__DESCRIPTION_2: '<mark>Neurons</mark> form circuit networks with dynamic activities',
    VISION__DESCRIPTION_3: 'The lab consider another layer of circuit, formed by <mark>astrocytes</mark> "star-shaped" cells',
    VISION__DESCRIPTION_4: 'For better understanding of brain mechanisms and therapeutic insights.',

    // Projects
    PROJECTS__SECTION_TITLE: 'Research Topics',
    PROJECTS__SECTION_SUBTITLE: 'Our group pursue how astrocytes are...',
    PROJECTS__PROJECT_1_TITLE: 'Computing Circuits',
    PROJECTS__PROJECT_1_DESCRIPTION: 'Astrocytes structually interact with neurons and other glia by means of myraid of their fine processes (~100 nm at the tips). Of note, a single astrocyte encompasses ~10,000~100,000 synapses. We study if, how and when they functionally interact each other for computing brain circuits, mainly by physiological approaches.',
    PROJECTS__PROJECT_2_TITLE: 'Regulating Behavior',
    PROJECTS__PROJECT_2_DESCRIPTION: 'Emerging evidences suggest that astrocytes respond to, and also alter multiple animal behaviors. Mutations in astrocyte genes cause abnormal human behaviors in disease settings. With tools with improved precision in time and space, we uncover the necessity and sufficiency of astrocytes in regulation of adaptive behavior.',
    PROJECTS__PROJECT_3_TITLE: 'Functionally Diverse',
    PROJECTS__PROJECT_3_DESCRIPTION: 'Tiling in the entire brain, astrocytes have been viewed as a homogeneous population of cells. This view has been recently challenged by reports demonstrating diversity of their molecular signature in different brain regions. By developing and deploying new strategies, we tease apart the functional diversity between defined astrocyte subpopulations.',

    // Publications
    PUBLICATIONS__SECTION_TITLE: 'Selected Publications',
    PUBLICATIONS__SEE_ALL: 'See All Publications →',

    // Our Laboratory
    OUR_LAB__SECTION_TITLE: 'Our Laboratory',
    OUR_LAB__DOWNLOAD_CV: 'Download CV',

    // Join Us
    JOIN_US__SECTION_TITLE: 'Join Our Lab',
    JOIN_US__DESCRIPTION: 'Welcome to learn the ground truth of astrocyte biology and physiology with us! We utilize wide range of experimental techniques; molecular biology, genetics, electrophysiology, imaging in situ and in vivo, animal behavior testing, histology and disease-related models. Our lab is located just outside Tokyo with a good access to unique and fun aspects of Japanese culture. Please come to enjoy science and life. \n \nYour country may provide you opportunities for fellowships to work at RIKEN (e.g., Marie Curie, Fulbright, HFSP; please see below for details). The Japanese Government also offers fellowships for young foreign researchers (JSPS). RIKEN has own fellowship program (SPRD). For international students, RIKEN CBS has a summer internship program and a joint graduate school program (IPA) which allows doctoral candidates to conduct research at RIKEN.',

    // Contact
    CONTACT__VISIT_US: 'Visit Us',
    CONTACT__VISIT_ADDRESS: '2-1 Hirosawa, S602 Central Research Building, Wako, Saitama, 351-0198, Japan',
    CONTACT__VISIT_DESCRIPTION: 'Direct bus sevices from NRT/HND airport to Wako-shi Station are available. The station to RIKEN takes 10 min by Taxi, 15 min by Bus #39 for Oizumigakuen or 20 min walk. See here (link) for details.',
    CONTACT__CONTACT_US: 'Contact Us',
    CONTACT__CONTACT_TEL: '+81-48-467-6896',
    CONTACT__CONTACT_EMAIL: 'jun.nagai[at]riken.jp',
    CONTACT__CONTACT_DESCRIPTION: 'Please feel free to contact for positions and potential collaborations.',

    // Footer
    FOOTER__TEAM_NAME : 'Laboratory for Glia-Neuron Circuit Dynamics',
    FOOTER__AFFILIATION: 'Jun Nagai Lab at RIKEN Center for Brain Science (CBS)',
    FOOTER__COPYRIGHTS: `© Jun Nagai, ${new Date().getFullYear()} - all rights reserved.`
  }
}