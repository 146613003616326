import React from 'react';
import Sketch from "react-p5";
import './style.scss';

const Neurons = (props) => {
  const { isActivated } = props;

  const setup = (p5, canvasParentRef) => {
    p5.createCanvas(320, 320).parent(canvasParentRef);
  };

  const draw = (p5) => {
    p5.clear();
    p5.background(0, 0, 0, 0);

    const millis = p5.millis();

    let neurons = [
      {
        position: { x: 70 + 2 * Math.cos(millis / 600), y: 120 + Math.sin(millis / 500) },
        size: isActivated ? 12 + 2 * Math.cos(millis / 800) : 10,
        connection: [1, 2, 4]
      },
      {
        position: { x: 90 + 3 * Math.sin(millis / 800), y: 205 + 2 * Math.cos(millis / 600), },
        size: isActivated ? 24 - 4 * Math.sin(millis / 1600) : 20,
        connection: [0, 2, 3]
      },
      {
        position: { x: 140 - Math.cos(millis / 400), y: 80 + Math.cos(millis / 500) },
        size: isActivated ? 30 + 5 * Math.sin(millis / 1200) : 25,
        connection: [0, 1, 3, 4]
      },
      {
        position: { x: 200 + Math.cos(millis / 300), y: 90 - 2 * Math.sin(millis / 400) },
        size: isActivated ? 12 - 2 * Math.cos(millis / 800) : 10,
        connection: [1, 2, 4]
      },
      {
        position: { x: 250 + 2 * Math.cos(millis / 600), y: 150 + 2 * Math.sin(millis / 600) },
        size: isActivated ? 17 + 2 * Math.sin(millis / 700) : 15,
        connection: [0, 2, 3]
      }
    ];

    neurons.forEach((srcNode, srcNodeIndex) => {
      srcNode.connection.forEach((distNodeIndex) => {
        if (srcNodeIndex < distNodeIndex) {
          if (isActivated) {
            if (p5.random(1)<0.0125) {
              const rd = p5.random(1);
              if (rd < 0.5) {
                p5.fill(255, 255, 255, 50);
                p5.ellipse(neurons[srcNodeIndex].position.x, neurons[srcNodeIndex].position.y, neurons[srcNodeIndex].size + 5, neurons[srcNodeIndex].size + 5);
                p5.ellipse(neurons[distNodeIndex].position.x, neurons[distNodeIndex].position.y, neurons[distNodeIndex].size + 5, neurons[distNodeIndex].size + 5);
                p5.stroke(251, 168, 168, 160);
                p5.strokeWeight(4);
              } else if (rd >= 0.5 && rd < 0.75) {
                p5.fill(168, 168, 251, 50);
                p5.ellipse(neurons[srcNodeIndex].position.x, neurons[srcNodeIndex].position.y, neurons[srcNodeIndex].size + 5, neurons[srcNodeIndex].size + 5);
                p5.ellipse(neurons[distNodeIndex].position.x, neurons[distNodeIndex].position.y, neurons[distNodeIndex].size + 5, neurons[distNodeIndex].size + 5);
                p5.stroke(168, 168, 251, 160);
                p5.strokeWeight(4);
              } else {
                p5.fill(168, 251, 168, 50);
                p5.ellipse(neurons[srcNodeIndex].position.x, neurons[srcNodeIndex].position.y, neurons[srcNodeIndex].size + 5, neurons[srcNodeIndex].size + 5);
                p5.ellipse(neurons[distNodeIndex].position.x, neurons[distNodeIndex].position.y, neurons[distNodeIndex].size + 5, neurons[distNodeIndex].size + 5);
                p5.stroke(168, 251, 168, 160);
                p5.strokeWeight(4);
              }
            } else {
              p5.stroke(251, 168, 168, 80);
              p5.strokeWeight(2);
            }
          } else {
            if (p5.random(1)<0.0025) {
              p5.fill(255, 255, 255, 50);
              p5.ellipse(neurons[srcNodeIndex].position.x, neurons[srcNodeIndex].position.y, neurons[srcNodeIndex].size + 5, neurons[srcNodeIndex].size + 5);
              p5.ellipse(neurons[distNodeIndex].position.x, neurons[distNodeIndex].position.y, neurons[distNodeIndex].size + 5, neurons[distNodeIndex].size + 5);
  
              p5.stroke(247, 100, 100, 160);
              p5.strokeWeight(3);
            } else {
              p5.stroke(247, 100, 100, 80);
              p5.strokeWeight(1);
            }
          }
          p5.line(neurons[srcNodeIndex].position.x, neurons[srcNodeIndex].position.y, neurons[distNodeIndex].position.x, neurons[distNodeIndex].position.y);
        }
      });
    });

    p5.noStroke();
    p5.fill(251, 168, 168, 255);
    neurons.forEach((node) => {
      p5.ellipse(node.position.x, node.position.y, node.size, node.size);
    });
  };

  return (
    <div className={`component--neuron`}>
      <Sketch setup={setup} draw={draw} />
    </div>
  );
};

export default Neurons;
