import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import ImageCollection from '../../components/ImageCollection';

import 'swiper/swiper.scss';
import './style.scss';

const Gallery = (props) => {
  const { gallery, breakpoint } = props;
  const imageGroups = {};
  if (gallery) {
    gallery.forEach(({ fields }) => {
      imageGroups[fields.group] = imageGroups[fields.group] ? imageGroups[fields.group] : [];
      if (fields.image && fields.image.length > 0) {
        imageGroups[fields.group].push({
          imgUrl: fields.image[0]?.url,
          isLarge: fields.large
        });
      }
    });
  }

  return (
    <div className="composition--gallery">
      <div className="container">
        <Swiper
          spaceBetween={1}
          slidesPerView={breakpoint === 'sm' || breakpoint === 'xs' ? 1 : 2}
        >
          {Object.keys(imageGroups).map((key, index) => {
            return (
              <SwiperSlide key={`gallery-swiper-slide-${index}`}>
                <ImageCollection imageGroup={imageGroups[key]} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

Gallery.propTypes = {
  
}


export default Gallery;
