import React from 'react';
import { useTranslation } from 'react-i18next';

import './style.scss';

import Member from '../../components/Member';

const Members = (props) => {
  const { members, language } = props;
  const { t } = useTranslation();
  return (
    <div className="composition--members">
      <div className="container">
        <div className="grid">
          <div className="col-12 section-title">
            <h3>{t('OUR_LAB__SECTION_TITLE')}</h3>
          </div>
          <div className="col-6_xs-hidden members-column">
            {members &&
              members.filter((m) => m.fields.column === 1).map(({ fields }, index) => {
                return (
                  <Member
                    key={`member-${index}`}
                    name={language === 'ja' ? fields.name_ja : fields.name}
                    title={language === 'ja' ? fields.title_ja : fields.title}
                    portraitUrl={fields.portrait && fields.portrait.length > 0 ? fields.portrait[0].url : ''}
                    cvUrl={fields.cv && fields.cv.length > 0 ? fields.cv[0].url : ''}
                    description={language === 'ja' ? fields.description_ja : fields.description}
                  />
                );
              })
            }
          </div>
          <div className="col-6_xs-hidden members-column">
            {members &&
              members.filter((m) => m.fields.column === 2).map(({ fields }, index) => {
                return (
                  <Member
                  key={`member-${index}`}
                    name={language === 'ja' ? fields.name_ja : fields.name}
                    title={language === 'ja' ? fields.title_ja : fields.title}
                    portraitUrl={fields.portrait && fields.portrait.length > 0 ? fields.portrait[0].url : ''}
                    cvUrl={fields.cv && fields.cv.length > 0 ? fields.cv[0].url : ''}
                    description={language === 'ja' ? fields.description_ja : fields.description}
                  />
                );
              })
            }
          </div>
          <div className="col-12_xs-visible_inf-hidden">
            {members &&
              members.map(({ fields }, index) => {
                return (
                  <Member
                    key={`member-${index}`}
                    name={language === 'ja' ? fields.name_ja : fields.name}
                    title={language === 'ja' ? fields.title_ja : fields.title}
                    portraitUrl={fields.portrait && fields.portrait.length > 0 ? fields.portrait[0].url : ''}
                    cvUrl={fields.cv && fields.cv.length > 0 ? fields.cv[0].url : ''}
                    description={language === 'ja' ? fields.description_ja : fields.description}
                  />
                );
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
};

Members.propTypes = {
  
}


export default Members;
