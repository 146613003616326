import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './style.scss';

const mapDispatchToProps = (dispatch) => ({

});

class Navigation extends Component {  
  componentDidMount() {
    this.setState({
      isMobileMenuOpen: false
    });
  }

  toggleMobileNav = () => {
    this.setState({
      ...this.state,
      isMobileMenuOpen: !this.state.isMobileMenuOpen
    })
  }

  render () {
    const { t, language } = this.props;
    return (
      <div className={`component--navigation ${this.props.isScrolled ? 'is-scrolled' : ''}`}>
        <div className="container desktop">
          <div className="navigation--title__container">
            {!this.props.isScrolled && <img className="navigation--logo" src={require('../../assets/images/lab_logo_wt.svg')} alt="lab-logo" />}
            {this.props.isScrolled && <img className="navigation--logo" src={require('../../assets/images/lab_logo_bk.svg')} alt="lab-logo" />}
            <div className="navigation--title" onClick={(event) => { this.props.scrollTo('top'); event.preventDefault(); }}>
              <h5 className="t heading">{t('NAVIGATION__TEAM_NAME')}</h5>
              <p className="subheading">{t('NAVIGATION__AFFILIATION')}</p>
            </div>
          </div>
          <div className="navigation--items">
            <a
              href="/#"
              className={`navigation--item ${this.props.scrollSection === 'vision' ? 'active' : ''}`}
              onClick={(event) => {
                this.props.scrollTo('vision');
                event.preventDefault();
              }
            }>
              {t('NAVIGATION__ITEM__VISION')}
            </a>
            <a
              href="/#" 
              className={`navigation--item ${this.props.scrollSection === 'publications' ? 'active' : ''}`}
              onClick={(event) => {
                this.props.scrollTo('publications');
                event.preventDefault();
              }
            }>
              {t('NAVIGATION__ITEM__PUBLICATIONS')}
            </a>
            <a
              href="/#" 
              className={`navigation--item ${this.props.scrollSection === 'ourLab' ? 'active' : ''}`}
              onClick={(event) => {
                this.props.scrollTo('ourLab');
                event.preventDefault();
              }
            }>
              {t('NAVIGATION__ITEM__OUR_LAB')}
            </a>
            <a
              href="/#"
              className={`navigation--item ${this.props.scrollSection === 'joinUs' ? 'active' : ''}`}
              onClick={(event) => {
                this.props.scrollTo('joinUs');
                event.preventDefault();
              }
            }>
              {t('NAVIGATION__ITEM__JOIN_US')}
            </a>
            <a
              href="/#"
              className={`navigation--item ${this.props.scrollSection === 'contact' ? 'active' : ''}`}
              onClick={(event) => {
                this.props.scrollTo('contact');
                event.preventDefault();
              }
            }>
              {t('NAVIGATION__ITEM__CONTACT')}
            </a>
            <a
              className="navigation--item"
              href={language === 'ja' ? '/' : '/ja'}
            >
              {t('NAVIGATION__ITEM__LANGUAGE')}
            </a>
          </div>
        </div>
        <div className="container mobile">
          <div className="navigation--title__container">
            {!this.props.isScrolled && <img className="navigation--logo" src={require('../../assets/images/lab_logo_wt.svg')} alt="lab-logo" />}
            <div className="navigation--title" onClick={(event) => { this.props.scrollTo('top'); event.preventDefault(); }}>
              <h5 className="t heading">{t('NAVIGATION__TEAM_NAME')}</h5>
              <p className="subheading">{t('NAVIGATION__AFFILIATION')}</p>
            </div>
          </div>
          <div className={`navigation--toggle ${this.state?.isMobileMenuOpen ? 'open' : 'close'} ${this.props.isScrolled ? 'scrolled' : ''}`} onClick={() => this.toggleMobileNav()}>
            <div className="bar top" />
            <div className="bar middle" />
            <div className="bar bottom" />
          </div>
          <div className={`navigation--items mobile ${this.state?.isMobileMenuOpen ? 'open' : 'close'}`} onClick={() => this.toggleMobileNav()}>
            <a
              href="/#"
              className="navigation--item"
              onClick={(event) => {
                this.props.scrollTo('vision');
                event.preventDefault(); 
              }}>{t('NAVIGATION__ITEM__VISION')}</a>
            <a
              href="/#"
              className="navigation--item"
              onClick={(event) => {
                this.props.scrollTo('publications');
                event.preventDefault(); 
              }}>{t('NAVIGATION__ITEM__PUBLICATIONS')}</a>
            <a
              href="/#"
              className="navigation--item"
              onClick={(event) => {
                this.props.scrollTo('ourLab');
                event.preventDefault(); 
              }}>{t('NAVIGATION__ITEM__OUR_LAB')}</a>
            <a
              href="/#"
              className="navigation--item"
              onClick={(event) => {
                this.props.scrollTo('joinUs');
                event.preventDefault(); 
              }}>{t('NAVIGATION__ITEM__JOIN_US')}</a>
            <a
              href="/#"
              className="navigation--item"
              onClick={(event) => {
                this.props.scrollTo('contact');
                event.preventDefault(); 
              }}>{t('NAVIGATION__ITEM__CONTACT')}</a>
            <a className="navigation--item" href={language === 'ja' ? '/' : '/ja'}>{t('NAVIGATION__ITEM__LANGUAGE')}</a>
          </div>
        </div>
      </div>
    );
  }
};

Navigation.propTypes = {
  isScrolled: PropTypes.bool
}

export default withTranslation()(connect(
  mapDispatchToProps
)(Navigation));
