import React from 'react';
import './style.scss';
import { withTranslation } from 'react-i18next';
import PWithTags from '../PWithTags';

const Member = (props) => {
  const { 
    name,
    title,
    portraitUrl,
    cvUrl,
    description,
    t
  } = props;
  const cvDownloadLink = cvUrl ? <div className="cv-link"><a target="_blank" rel="noopener noreferrer" href={cvUrl}>{t('OUR_LAB__DOWNLOAD_CV')}</a></div> : null;

  return (
    <div className="component--member">
      <div className="grid">
        <div className="col-4_md-6_sm-12">
        <div className="image-container" style={{ backgroundImage: `url(${portraitUrl})` }}></div>
        </div>
        <div className="col-8_md-6_sm-12 profile-header">
          <div className="name-title">
            <h6>{name}</h6>
            <p>{title}</p>
          </div>
          {cvDownloadLink}
        </div>
        <div className="col-12">
          <PWithTags content={description} />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Member);
