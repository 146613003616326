import { FETCH_CMS__GALLERY__UPDATE } from './constants';

const initialState = {
  data: null
};

function galleryReducer(state = initialState, action) {
  switch(action.type) {
    case FETCH_CMS__GALLERY__UPDATE:
      return {
        ...state,
        data: action.data
      };
    
    default:
      return state;
  }
}

export default galleryReducer;