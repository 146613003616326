import {
  FETCH_CMS__INITIATE,
  FETCH_CMS__SUCCESS,
  FETCH_CMS__ERROR
} from './constants';

const initialState = {
  isLoading: false,
  data: null,
  error: null
};

function apiReducer(state = initialState, action) {
  switch(action.type) {
    case FETCH_CMS__INITIATE:
      return {
        ...state,
        isLoading: true
      }

    case FETCH_CMS__SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data,
        error: null
      };
    
    case FETCH_CMS__ERROR:
        return {
          ...state,
          isLoading: false,
          data: null,
          error: action.error
        };

    default:
      return state;
  }
}

export default apiReducer;