import React from 'react';
import { useTranslation } from 'react-i18next';

import './style.scss';

const Footer = (props) => {
  const { t } = useTranslation();
  return (
    <div className="composition--footer">
      <div className="container">
        <h6 className="t">{t('FOOTER__TEAM_NAME')}</h6>
        <p>{t('FOOTER__AFFILIATION')}</p>
        <div className="logo-container">
          <img className="nagai-lab-logo" src={require('../../assets/images/lab_logo_full.svg')} alt="riken-logo" />
          <img className="riken-logo" src={require('../../assets/images/riken_logo.svg')} alt="riken-logo" />
          <img className="cbs-logo" src={require('../../assets/images/cbs_logo.svg')} alt="riken-logo" />
        </div>
        <p className="copyright">{t('FOOTER__COPYRIGHTS')}</p>
      </div>
    </div>
  );
};

Footer.propTypes = {
  
}


export default Footer;
