import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Loader = (props) => {
  return (
    <div className={`loader--component ${props.isLoading ? 'active' : ''}`}>
        <div className="lds-ellipsis">
          <div className="ellipse one" />
          <div className="ellipse two" />
          <div className="ellipse three" />
          <div className="ellipse four" />
        </div>
    </div>
  );
};

Loader.propTypes = {
  isLoading: PropTypes.bool
}


export default Loader;
