import React from 'react';
import { withTranslation, Trans } from 'react-i18next';

import './style.scss';

import Neurons from '../../components/Neurons';

const Vision = (props) => {
  const { positionY, breakpoint, t, offset } = props;

  const parallaxOffset = (id, y) => {
    const parallaxWeight = 16;

    switch (id) {
      case 0:
        if (y <  1/12) return 0;
        if (y >= 1/12 && y < 1/6) return -((y-1/12)*12) * parallaxWeight;
        if (y >= 1/6) return -parallaxWeight;
        break;

      case 1:
        if (y <  1/6) return parallaxWeight;
        if (y >= 1/6 && y < 1/4) return parallaxWeight * (1-(y-1/6)*12);
        if (y >= 1/4 && y < 5/12) return 0;
        if (y >= 5/12 && y < 1/2) return -parallaxWeight * ((y-5/12)*12);
        if (y >= 1/2) return -parallaxWeight;
        break;
      
      case 2:
        if (y <  1/2) return parallaxWeight;
        if (y >= 1/2 && y < 7/12) return parallaxWeight * (1-(y-1/2)*12);
        if (y >= 7/12 && y < 3/4) return 0;
        if (y >= 3/4 && y < 5/6) return -parallaxWeight * ((y-3/4)*12);
        if (y >= 5/6) return -parallaxWeight;
        break;
    
      case 3:
        if (y <  5/6) return parallaxWeight;
        if (y >= 5/6 && y < 11/12) return parallaxWeight * (1-(y-5/6)*12);
        if (y >= 11/12) return 0;
        break;

      default:
        return 0;
    }
  }

  const calcOpacity = (id, y) => {
    switch (id) {
      case 0:
        if (y <  1/12) return 1;
        if (y >= 1/12 && y < 1/6) return 1-((y-1/12)*12);
        if (y >= 1/6) return 0;
        break;

      case 1:
        if (y <  1/6) return 0;
        if (y >= 1/6 && y < 1/4) return (y-1/6)*12;
        if (y >= 1/4 && y < 5/12) return 1;
        if (y >= 5/12 && y < 1/2) return 1-((y-5/12)*12);
        if (y >= 1/2) return 0;
        break;
      
      case 2:
        if (y <  1/2) return 0;
        if (y >= 1/2 && y < 7/12) return (y-1/2)*12;
        if (y >= 7/12 && y < 3/4) return 1;
        if (y >= 3/4 && y < 5/6) return 1-((y-3/4)*12);
        if (y >= 5/6) return 0;
        break;
    
      case 3:
        if (y <  5/6) return 0;
        if (y >= 5/6 && y < 11/12) return (y-5/6)*12;
        if (y >= 11/12) return 1;
        break;
      
      default:
        return 0;
    }
  }

  const sittingHumanStyleOverride = (y) => {
    if (y < 1/12) {
      return {
        opacity: 1,
        backgroundSize: '280px',
        backgroundPosition: '50% 50%'
      };
    } else if (y >= 1/12 && y < 1/6) {
      return {
        opacity: (1 - 0.07) * (1-((y-1/12)*12)) + 0.07,
        backgroundSize: `${(2000 - 280) * Math.pow(((y-1/12)*12), 2) + 280}px`,
        backgroundPosition: `${(57.5 - 50) * Math.sqrt((y-1/12)*12) + 50}% ${(10 - 50) * Math.pow((y-1/12)*12, 1/8) + 50}%`
      }
    } else if (y >= 1/6 && y < 5/12) {
      return {
        opacity: 0.07,
        backgroundSize: '2000px',
        backgroundPosition: '57.5% 10%'
      }
    } else if (y >= 5/12 && y < 7/12) {
      return {
        opacity: 0.07,
        backgroundSize: '2000px',
        backgroundPosition: `${(57.8 - 57.5) * ((y-5/12)*6) + 57.5}% 10%`,
        transform: `rotate(${-18 * ((y-5/12)*6)}deg)`
      }
    } else if (y >= 7/12) {
      return {
        opacity: 0,
        backgroundSize: '2000px',
        backgroundPosition: '57.8% 10%',
        transform: `rotate(${-18}deg)`
      }
    }
  }

  const standingHumanStyleOverride = (y) => {
    if (y < 7/12) {
      return {
        opacity: 0
      };
    } else if (y >= 7/12 && y < 5/6) {
      return {
        opacity: 0.07,
        backgroundSize: '1720px',
        backgroundPosition: '52.8% 4.7%',
      };
    } else if (y >= 5/6 && y < 11/12) {
      return {
        opacity: (1 - 0.07) * (y-5/6)*12 + 0.07,
        backgroundSize: `${(210 - 1720)*Math.pow((y-5/6)*12, 1/2) + 1720}px`,
        backgroundPosition: `${(50-52.8)*(y-5/6)*12 + 52.8}% ${(-25-4.7)*Math.pow((y-5/6)*12, 8) + 4.7}%`
      }
    } else if (y >= 11/12) {
      return {
        opacity: 1,
        backgroundSize: '210px',
        backgroundPosition: '50% -25%'
      }
    }
  }

  const brainStyleOverride = (y) => {
    if (y < 1/12) {
      return {
        opacity: 0
      };
    } else if (y >= 1/12 && y < 1/6) {
      return {
        opacity: (y-1/12)*12*3 - 2 < 0 ? 0 : (y-1/12)*12*3 - 2,
        backgroundSize: `${(232 - 100) * Math.pow((y-1/12)*12, 2) + 100}px`,
        backgroundPosition: `${(51-47) * (y-1/12)*12*2 + 47}% ${(43-10) * Math.pow((y-1/12)*12, 1/2) * 2 - 33}%`,
        transform: 'rotate(18deg)',
      };
    } else if (y >= 1/6 && y < 5/12) {
      return {
        opacity: 1,
        backgroundSize: '232px',
        backgroundPosition: '51% 43%',
        transform: 'rotate(18deg)'
      };
    } else if (y >= 5/12 && y < 7/12) {
      return {
        opacity: 1,
        backgroundSize: '232px',
        backgroundPosition: `${(51-45)*(1-(y-5/12)*6) + 45}% 43%`,
        transform: `rotate(${18*(1-(y-5/12)*6)}deg)`
      };
    } else if (y >= 7/12 && y < 5/6) {
      return {
        opacity: 1,
        backgroundSize: '232px',
        backgroundPosition: '45% 43%'
      };
    } else if (y >= 5/6 && y < 11/12) {
      return {
        opacity: 1-(y-5/6)*12*3 > 0 ? 1-(y-5/6)*12*3 : 0,
        backgroundSize: `${(232 - 100) * Math.pow((1-(y-5/6)*12*2), 2) + 100}px`,
        backgroundPosition: `${(53-45) * (y-5/6)*12*2 + 45}% ${(13-43) * Math.pow((y-5/6)*12, 1/2)*2 + 43}%`
      };
    } else if (y >= 11/12) {
      return {
        opacity: 0
      };
    }
  }

  const neuronAnimationStyleOverride = (y) => {
    if (y < 1/12) {
      return {
        opacity: 0
      };
    } else if (y >= 1/12 && y < 1/6) {
      return {
        opacity: (y-1/12)*12*5 - 4 < 0 ? 0 : (y-1/12)*12*5 - 4,
        transform: `rotate(18deg) scale(${(y-1/12)*12})`,
        marginTop: `-${100 * (1-(y-1/12)*12)}px`
      };
    } else if (y >= 1/6 && y < 5/12) {
      return {
        opacity: 1,
        transform: 'rotate(18deg)'
      };
    } else if (y >= 5/12 && y < 7/12) {
      return {
        opacity: 1,
        transform: `rotate(${18*(1-(y-5/12)*6)}deg)`
      };
    } else if (y >= 7/12 && y < 5/6) {
      return {
        opacity: 1,
      };
    } else if (y >= 5/6 && y < 11/12) {
      return {
        opacity: 1-(y-5/6)*12*5 > 0 ? 1-(y-5/6)*12*5 : 0,
        transform: `scale(${1-(y-5/6)*12}`,
        marginTop: `-${(y-5/6)*12*200}px`
      };
    } else if (y >= 11/12) {
      return {
        opacity: 0
      };
    }
  }

  const astrocyteAnimationStyleOverride = (y) => {
    if (y < 1/12) {
      return {
        opacity: 0
      };
    } else if (y >= 1/12 && y < 1/6) {
      return {
        opacity: 0,
        transform: `rotate(18deg) scale(${(y-1/12)*12})`,
        marginTop: `-${100 * (1-(y-1/12)*12)}px`
      };
    } else if (y >= 1/6 && y < 5/12) {
      return {
        opacity: 0,
        transform: 'rotate(18deg)'
      };
    } else if (y >= 5/12 && y < 7/12) {
      return {
        opacity: (y-5/12)*6,
        transform: `rotate(${18*(1-(y-5/12)*6)}deg)`
      };
    } else if (y >= 7/12 && y < 5/6) {
      return {
        opacity: 1,
      };
    } else if (y >= 5/6 && y < 11/12) {
      return {
        opacity: 1-(y-5/6)*12*5 > 0 ? 1-(y-5/6)*12*5 : 0,
        transform: `scale(${1-(y-5/6)*12}`,
        marginTop: `-${(y-5/6)*12*200}px`
      };
    } else if (y >= 11/12) {
      return {
        opacity: 0
      };
    }
  }

  const astrocyteCellAnimationStyleOverride = (id, y) => {
    if (y >= 5/12 && y < 7/12) {
      if (id === 0) {
        return {
          transform: `rotate(${(135 - 45)*(1-(y-5/12)*6) + 45}deg) scale(${1.2*(y-5/12)*6})`
        };
      } else if (id === 1) {
        return {
          transform: `rotate(${135*(1-(y-5/12)*6)}deg) scale(${1.8*(y-5/12)*6})`
        };
      } else if (id === 2) {
        return {
          transform: `rotate(${(195 - 135)*(1-(y-5/12)*6) + 135}deg) scale(${1.4*(y-5/12)*6})`
        };
      }  
    }
  }

  return (
    <div className={`composition--our-vision ${props.scrollArea === 'vision' ? 'is-vision' : ''}`} style={{ height: props.containerHeight }}>
      <div
        className="container--background"
        style={{
          height: props.contentsHeight,
          top: props.scrollArea === 'vision' ? `calc(50vh - ${(props.contentsHeight / 2) + offset}px)`
          : (props.scrollArea === 'pre' ? 0 : props.containerHeight - props.contentsHeight)}}
        >
      </div>
      <div className="container">

        {/* Header */}
        <h2
          className="header"
          style={{
            top: props.scrollArea === 'vision' ? `calc(50vh - ${props.contentsHeight/2 - (breakpoint === 'xs' || breakpoint === 'sm' ? 36 : 64) + offset}px)` 
            : props.scrollArea === 'pre' ? (breakpoint === 'xs' || breakpoint === 'sm' ? '4px' : '32px') : props.containerHeight -  props.contentsHeight + (breakpoint === 'xs' || breakpoint === 'sm' ? 4 : 32)}}>
              {t('VISION__SECTION_TITLE')}
        </h2>
        
        {/* Images */}
        <div
          className="images background"
          style={{
            top: props.scrollArea === 'vision' ? `calc(50vh + ${props.contentsHeight/2 - (breakpoint === 'xs' || breakpoint === 'sm' ? 428 : 484) - offset}px)` 
              : props.scrollArea === 'pre' ? props.contentsHeight - (breakpoint === 'xs' || breakpoint === 'sm' ? 428 : 484) : props.containerHeight - (breakpoint === 'xs' || breakpoint === 'sm' ? 428 : 484)}}></div>

        <div
          className="images human-sitting"
          style={{
            ...sittingHumanStyleOverride(positionY),
            top: props.scrollArea === 'vision' ? `calc(50vh + ${props.contentsHeight/2 - (breakpoint === 'xs' || breakpoint === 'sm' ? 428 : 484) - offset}px)` 
              : props.scrollArea === 'pre' ? props.contentsHeight - (breakpoint === 'xs' || breakpoint === 'sm' ? 428 : 484) : props.containerHeight - (breakpoint === 'xs' || breakpoint === 'sm' ? 428 : 484)}}></div>
        
        <div
          className="images human-standing"
          style={{
            ...standingHumanStyleOverride(positionY),
            top: props.scrollArea === 'vision' ? `calc(50vh + ${props.contentsHeight/2 - (breakpoint === 'xs' || breakpoint === 'sm' ? 428 : 484) - offset}px)` 
              : props.scrollArea === 'pre' ? props.contentsHeight - (breakpoint === 'xs' || breakpoint === 'sm' ? 428 : 484) : props.containerHeight - (breakpoint === 'xs' || breakpoint === 'sm' ? 428 : 484)}}></div>
        
        <div
          className="images brain"
          style={{
            ...brainStyleOverride(positionY),
            top: props.scrollArea === 'vision' ? `calc(50vh + ${props.contentsHeight/2 - (breakpoint === 'xs' || breakpoint === 'sm' ? 428 : 484) + offset}px)` 
              : props.scrollArea === 'pre' ? props.contentsHeight - (breakpoint === 'xs' || breakpoint === 'sm' ? 428 : 484) : props.containerHeight - (breakpoint === 'xs' || breakpoint === 'sm' ? 428 : 484)}}></div>
        
        {/* Neuron Anime */}
        <div
          className="neuron-animation-container"
          style={{
            ...neuronAnimationStyleOverride(positionY),
            display: props.scrollArea === 'vision' ? '' : 'none'
          }}
        >
          <Neurons isActivated={positionY > 7/12} />
        </div>

        {/* Astrocyte */}
        <div
          className="astrocyte-animation-container"
          style={{
            ...astrocyteAnimationStyleOverride(positionY),
            display: props.scrollArea === 'vision' ? '' : 'none'
          }}
        >
          <div className={`astrocyte cell-1 ${positionY < 7/12 ? 'not-rendered' : ''}`} style={{
            ...astrocyteCellAnimationStyleOverride(0, positionY)
          }} />
          <div className={`astrocyte cell-2 ${positionY < 7/12 ? 'not-rendered' : ''}`} style={{
            ...astrocyteCellAnimationStyleOverride(1, positionY)
          }}/>
          <div className={`astrocyte cell-3 ${positionY < 7/12 ? 'not-rendered' : ''}`} style={{
            ...astrocyteCellAnimationStyleOverride(2, positionY)
          }}/>
        </div>

        {/* Copies */}
        <p
          className="body"
          style={
            {
              top: props.scrollArea === 'vision' ? `calc(50vh + ${props.contentsHeight/2 - (breakpoint === 'xs' || breakpoint === 'sm' ? 84 : 128) + parallaxOffset(0, positionY) - offset}px)` 
              : props.scrollArea === 'pre' ? props.contentsHeight - (breakpoint === 'xs' || breakpoint === 'sm' ? 84 : 128) : props.containerHeight - (breakpoint === 'xs' || breakpoint === 'sm' ? 84 : 128),
              opacity: calcOpacity(0, positionY)
            }}>
              {t('VISION__DESCRIPTION_1')}
        </p>
        <p
          className="body"
          style={{
            top: props.scrollArea === 'vision' ? `calc(50vh + ${props.contentsHeight/2 - (breakpoint === 'xs' || breakpoint === 'sm' ? 84 : 128) + parallaxOffset(1, positionY) - offset}px)` 
            : props.scrollArea === 'pre' ? props.contentsHeight - (breakpoint === 'xs' || breakpoint === 'sm' ? 84 : 128) : props.containerHeight - (breakpoint === 'xs' || breakpoint === 'sm' ? 84 : 128), 
            opacity: calcOpacity(1, positionY)}}>
              <Trans
                i18nKey='VISION__DESCRIPTION_2'
                defaults={t('VISION__DESCRIPTION_2')}
                components={{ mark: <span className="highlight neuron" /> }}
              />
        </p>
        <p
          className="body"
          style={{
            top: props.scrollArea === 'vision' ? `calc(50vh + ${props.contentsHeight/2 - (breakpoint === 'xs' || breakpoint === 'sm' ? 84 : 128) + parallaxOffset(2, positionY) - offset}px)` 
            : props.scrollArea === 'pre' ? props.contentsHeight - (breakpoint === 'xs' || breakpoint === 'sm' ? 84 : 128) : props.containerHeight - (breakpoint === 'xs' || breakpoint === 'sm' ? 84 : 128),
            opacity: calcOpacity(2, positionY)}}>
              <Trans
                i18nKey='VISION__DESCRIPTION_3'
                defaults={t('VISION__DESCRIPTION_3')}
                components={{ mark: <span className="highlight astrocyte" /> }}
              />
        </p>
        <p
          className="body"
          style={{
            top: props.scrollArea === 'vision' ? `calc(50vh + ${props.contentsHeight/2 - (breakpoint === 'xs' || breakpoint === 'sm' ? 84 : 128) + parallaxOffset(3, positionY) - offset}px)` 
            : props.scrollArea === 'pre' ? props.contentsHeight - (breakpoint === 'xs' || breakpoint === 'sm' ? 84 : 128) : props.containerHeight - (breakpoint === 'xs' || breakpoint === 'sm' ? 84 : 128),
            opacity: calcOpacity(3, positionY)}}>
              {t('VISION__DESCRIPTION_4')}
        </p>
      </div>
    </div>
  );
};

Vision.propTypes = {
  
}


export default withTranslation()(Vision);
