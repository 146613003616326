import React from 'react';
import { withTranslation } from 'react-i18next';

import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Highlight from '../../components/Highlight';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import './style.scss';

SwiperCore.use([Navigation]);

const Highlights = (props) => {
  const { highlights, breakpoint, t, language } = props;
  return (
    <div className="composition--highlights">
      <div className="container">
        <div className="grid">
          <div className="col-12">
            <h3 className="section-title">{t('HIGHLIGHTS__SECTION_TITLE')}</h3>
          </div>
          <div className="col-12">
            <Swiper
              spaceBetween={breakpoint === 'sm' || breakpoint === 'xs' ? 8 : 32}
              slidesPerView={breakpoint === 'xs' ? 1 : 2}
              navigation
            >
              {highlights &&
                highlights.map((highlight, index) => {
                  return (
                    <SwiperSlide key={`highlight-swiper-slide-${index}`}>
                      <Highlight
                        header={language === 'ja' ? highlight.fields.title_ja : highlight.fields.title}
                        description={language === 'ja' ? highlight.fields.description_ja : highlight.fields.description}
                        thumbnailUrl={highlight.fields.thumbnail[0].url}
                        link={highlight.fields.link}
                      />
                    </SwiperSlide>
                  );
                })
              }
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

Highlights.propTypes = {
  
}


export default withTranslation()(Highlights);
