import React from 'react';

const PWithTags = (props) => {
  if (!props.content) return ( <></> );
  const taggedText = props.content ? props.content.match(/\\.*?\\/g) : '';
  if (taggedText) {
    let contentOrg = props.content;
    const tags = [];
    taggedText.forEach(italicItem => {
      tags.push(<span>{contentOrg.substring(0, contentOrg.indexOf(italicItem))}</span>);
      tags.push(<span className="italic">{italicItem.replace(/\\/g, '')}</span>)
      contentOrg = contentOrg.substring(contentOrg.indexOf(italicItem) + italicItem.length + 1);
    });
    tags.push(<span>{contentOrg}</span>);
    return (
      <p>{tags.map(item => {
        return item;
      })}</p>
    );
  } else {
    return <p>{props.content}</p>;
  }
};

export default PWithTags;
