import React from 'react';
import './style.scss';

import PWithTags from '../PWithTags';

const Highlight = (props) => {
  const { 
    header,
    description,
    thumbnailUrl,
    link
  } = props;

  const openLink = (link) => {
    window.open(link, '_blank');
  }

  return (
    <div className="component--highlight" onClick={() => openLink(link)}>    
      <div className="image-container" style={{backgroundImage: `url(${thumbnailUrl})`}}></div>
      <div className="captions">
        <p className="header">{header}</p>
        {/* <p className="description"> */}
          <PWithTags content={description} />
        {/* </p> */}
      </div>
    </div>
  );
};

export default Highlight;
