// Dependencies
import { combineReducers } from 'redux';

// Children Reducers
import highlightsReducer from './redux/highlights/reducer';
import publicationsReducer from './redux/publications/reducer';
import membersReducer from './redux/members/reducer';
import galleryReducer from './redux/gallery/reducer';
import openingsReducer from './redux/openings/reducer';
import apiReducer from './redux/api/reducer';

export default function createReducer(injectedReducers) {
  return combineReducers({
    api: apiReducer,
    highlights: highlightsReducer,
    publications: publicationsReducer,
    members: membersReducer,
    gallery: galleryReducer,
    openings: openingsReducer,
    ...injectedReducers
  });
}
