import React, { Component } from 'react';
import { connect } from 'react-redux';
import { animateScroll as scroll } from 'react-scroll';
import i18next from 'i18next';

// Actions
import * as ApiActions from '../../redux/api/actions';

// Component
import Loader from '../../components/Loader';
import Navigation from '../../components/Navigation';

// Compositions
import Header from '../../composition/Header';
import Highlights from '../../composition/Highlights';
import Vision from '../../composition/Vision';
import Projects from '../../composition/Projects';
import Publications from '../../composition/Publications';
import Members from '../../composition/Members';
import Gallery from '../../composition/Gallery';
import JoinUs from '../../composition/JoinUs';
import Contact from '../../composition/Contact';
import Footer from '../../composition/Footer';

// Styles
import './style.scss';

// Stores
const mapStateToProps = (state) => {
  const data = {};
  if (!state.highlights.loading || state.highlights.data) {
    data.highlights = state.highlights.data?.filter(d => d.fields.active);
  }
  if (!state.publications.loading || state.publications.data) {
    data.publications = state.publications.data?.filter(d => d.fields.active);
  }
  if (!state.members.loading || state.members.data) {
    data.members = state.members.data?.filter(d => d.fields.active);
  }
  if (!state.gallery.loading || state.gallery.data) {
    data.gallery = state.gallery.data?.filter(d => d.fields.active);
  }
  if (!state.openings.loading || state.openings.data) {
    data.openings = state.openings.data?.filter(d => d.fields.active);
  }

  data.isLoading = state?.api?.isLoading;
  return data;
};

const mapDispatchToProps = (dispatch) => ({
  onPageLoad: () => {
    dispatch(ApiActions.fetchCMS__initiate());
  }
});

class App extends Component {  
  constructor(props) {
    super(props);

    // Refs
    // Wrappers + Vision
    this.ref__preVision = React.createRef();
    this.ref__vision = React.createRef();
    this.ref__postVision = React.createRef();

    // Other Comps
    this.ref__header = React.createRef();
    this.ref__highlights = React.createRef();
    this.ref__projects = React.createRef();
    this.ref__publications = React.createRef();
    this.ref__our_lab = React.createRef();
    this.ref__gallery = React.createRef();
    this.ref__join_us = React.createRef();
    this.ref__contact = React.createRef();

    this.visionAreaOffset = 1200;

    this.state = {
      scrollY: 0,
      viewWidth: window.innerWidth,
      viewHeight: window.innerHeight,
      visionContentsHeight: this.getBreakpoint(window.innerWidth) === 'xs' || this.getBreakpoint(window.innerWidth) === 'sm' ? 528 : 640,
      visionContainerHeight: 2640,
      scrollArea: 'pre',
      scrollSection: '',
      scrollAreaBoundaries: {
        pre: 0,
        post: 0
      },
      elementHeights: {},
      isDataLoading: this.props.isLoading,
      language: 'en',
      isAppReady: false,
    };

    // Event binding to the instance
    this.handleScroll = this.handleScroll.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);

    // Page Load Event
    this.props.onPageLoad();
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
    window.addEventListener('resize', this.handleWindowResize, true);

    const scrollAreaBoundaries = {
      pre: this.ref__preVision.current.clientHeight - (this.state.viewHeight - this.state.visionContentsHeight) / 2,
      post: document.body.scrollHeight - this.ref__postVision.current.clientHeight - (this.state.viewHeight + this.state.visionContentsHeight) / 2
    };
    this.setState({
      ...this.state,
      scrollAreaBoundaries
    });

    this.handleScroll();
    this.setLanguage(this.props.params.language);
  }

  componentWillUnmount() {
    window.scrollTo(0, 0);
    window.removeEventListener('scroll', this.handleScroll, true);
    window.removeEventListener('resize', this.handleWindowResize, true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoading && !this.props?.isLoading) {
      setTimeout(() => {
        window.onbeforeunload = () => {
          window.scrollTo(0, 0);
        }
        this.handleScroll();
        this.setState({
          ...this.state,
          isAppReady: true
        });
      });
    }
  }

  setLanguage(language) {
    if (language !== 'ja') language = 'en';
    i18next.changeLanguage(language, (err, t) => {
      if (err) console.log(err);
    });
    this.setState({
      ...this.state,
      language
    })
  }

  handleScroll() {
    const scrollY = window.scrollY;

    const visionBeginning = this.ref__header.current.clientHeight + this.ref__highlights.current.clientHeight;
    const publicationBeginning = visionBeginning + this.state.visionContainerHeight + this.ref__projects.current.clientHeight;
    const ourLabBeginning = publicationBeginning + this.ref__publications.current.clientHeight;
    const joinUsBeginning = ourLabBeginning + this.ref__our_lab.current.clientHeight + this.ref__gallery.current.clientHeight;
    const contactBeginning = joinUsBeginning + this.ref__join_us.current.clientHeight;

    const scrollAreaBoundaries = {
      pre: this.ref__preVision.current.clientHeight - (this.state.viewHeight - this.state.visionContentsHeight) / 2,
      post: document.body.scrollHeight - this.ref__postVision.current.clientHeight - (this.state.viewHeight + this.state.visionContentsHeight) / 2
    };
    let scrollArea = '';
    if (this.state.scrollY < this.state.scrollAreaBoundaries.pre - this.visionAreaOffset) {
      scrollArea = 'pre';
    } else if (this.state.scrollY > this.state.scrollAreaBoundaries.post + this.visionAreaOffset) {
      scrollArea = 'post';
    } else if (this.state.scrollY <= this.state.scrollAreaBoundaries.post + this.visionAreaOffset && this.state.scrollY >= this.state.scrollAreaBoundaries.pre - this.visionAreaOffset)  {
      scrollArea = 'vision';
    } else {
      scrollArea = 'pre';
    }

    let scrollSection;
    if (visionBeginning > (scrollY + 144)) {
      scrollSection = '';
    } else if (visionBeginning <= (scrollY + 144) && publicationBeginning > (scrollY + 144)) {
      scrollSection = 'vision';
    } else if (publicationBeginning <= (scrollY + 144) && ourLabBeginning > (scrollY + 144)) {
      scrollSection = 'publications';
    } else if (ourLabBeginning <= (scrollY + 144) && joinUsBeginning > (scrollY + 144)) {
      scrollSection = 'ourLab';
    } else if (joinUsBeginning <= (scrollY + 144) && contactBeginning > (scrollY + 144)) {
      scrollSection = 'joinUs';
    } else if (contactBeginning <= (scrollY + 144)) {
      scrollSection = 'contact';
    }

    this.setState({
      ...this.state,
      scrollY,
      scrollArea,
      scrollSection,
      scrollAreaBoundaries
    });
  }

  scrollTo(identifier) {
    if (
      !this.ref__header.current ||
      !this.ref__highlights.current ||
      !this.ref__projects.current ||
      !this.ref__publications.current ||
      !this.ref__our_lab.current ||
      !this.ref__gallery.current ||
      !this.ref__join_us.current ||
      !this.ref__contact.current
    ) return;

    const visionBeginning = this.ref__header.current.clientHeight + this.ref__highlights.current.clientHeight;
    const publicationBeginning = visionBeginning + this.state.visionContainerHeight + this.ref__projects.current.clientHeight;
    const ourLabBeginning = publicationBeginning + this.ref__publications.current.clientHeight;
    const joinUsBeginning = ourLabBeginning + this.ref__our_lab.current.clientHeight + this.ref__gallery.current.clientHeight;
    const contactBeginning = joinUsBeginning + this.ref__join_us.current.clientHeight;

    switch (identifier) {
      case 'top':
        scroll.scrollTo(0, { duration: 300 });
        break;
      case 'vision':
        scroll.scrollTo(visionBeginning - 144, { duration: 300 });
        break;
      case 'publications':
        scroll.scrollTo(publicationBeginning - 144, { duration: 300 });
        break;
      case 'ourLab':
        scroll.scrollTo(ourLabBeginning - 144, { duration: 300 });
        break;
      case 'joinUs':
        scroll.scrollTo(joinUsBeginning - 144, { duration: 300 });
        break;
      case 'contact':
        scroll.scrollTo(contactBeginning - 144, { duration: 300 });
        break;
      default:
        break;
    }
  }

  handleWindowResize() {
    this.setState({
      ...this.state,
      viewWidth: window.innerWidth,
      viewHeight: window.innerHeight,
      visionContentsHeight: this.getBreakpoint(window.innerWidth) === 'xs' || this.getBreakpoint(window.innerWidth) === 'sm' ? 528 : 640,
    });
  }

  getBreakpoint(x) {
    if (x < 576) {
      return 'xs';
    } else if (x >= 576 && x < 768) {
      return 'sm';
    } else if (x >= 768 && x < 1024) {
      return 'md';
    } else if (x >= 1024 && x < 1280) {
      return 'lg';
    } else if (x >= 1280) {
      return 'xl';
    }
  }

  getPreVisionTopInVisionMode() {
    if (this.state.scrollY < this.state.scrollAreaBoundaries.pre) {
      return -this.state.scrollAreaBoundaries.pre + (this.state.scrollAreaBoundaries.pre - this.state.scrollY);
    } else if (this.state.scrollY >= this.state.scrollAreaBoundaries.pre && this.state.scrollY <= this.state.scrollAreaBoundaries.post) {
      return -this.state.scrollAreaBoundaries.pre;
    } else if (this.state.scrollY > this.state.scrollAreaBoundaries.post)  {
      return -this.state.scrollAreaBoundaries.pre + (this.state.scrollAreaBoundaries.post - this.state.scrollY);
    }
  }

  getPostVisionTopInVisionMode() {
    if (this.state.scrollY < this.state.scrollAreaBoundaries.pre) {
      return (this.state.viewHeight + this.state.visionContentsHeight) / 2 + (this.state.scrollAreaBoundaries.pre - this.state.scrollY);
    } else if (this.state.scrollY >= this.state.scrollAreaBoundaries.pre && this.state.scrollY <= this.state.scrollAreaBoundaries.post) {
      return (this.state.viewHeight + this.state.visionContentsHeight) / 2;
    } else if (this.state.scrollY > this.state.scrollAreaBoundaries.post)  {
      return (this.state.viewHeight + this.state.visionContentsHeight) / 2 + (this.state.scrollAreaBoundaries.post - this.state.scrollY);
    } else {
      return (this.state.viewHeight + this.state.visionContentsHeight) / 2;
    }
  }

  getOffsetInVisionMode() {
    if (this.state.scrollY < this.state.scrollAreaBoundaries.pre) {
      return this.state.scrollY - this.state.scrollAreaBoundaries.pre;
    } else if (this.state.scrollY >= this.state.scrollAreaBoundaries.pre && this.state.scrollY <= this.state.scrollAreaBoundaries.post) {
      return 0;
    } else if (this.state.scrollY > this.state.scrollAreaBoundaries.post)  {
      return this.state.scrollY - this.state.scrollAreaBoundaries.post;
    } else {
      return 0;
    }
  }

  render() {
    return (
      <>
      <div className={`app ${this.state.language === 'ja' ? 'japanese' : ''} ${this.state.isAppReady ? '' : 'not-ready'}`}>
        {/* Nav Bar */}
        <Navigation
          isScrolled={this.state.scrollY > this.state.viewHeight * 0.6}
          scrollTo={(identifier) => this.scrollTo(identifier)}
          scrollSection={this.state.scrollSection}
          language={this.state.language}
        />

        {/* Before Vision */}
        <div 
          ref={this.ref__preVision}
          className={`app-wrapper--pre-scrollable ${this.state.scrollArea}`}
          style={{ top: this.state.scrollArea === 'vision' ? this.getPreVisionTopInVisionMode()
          : (this.state.scrollArea === 'pre' ? 0 : this.state.visionContainerHeight - this.state.visionContentsHeight) }}>

          {/* Header */}
          <div ref={this.ref__header}>
            <Header
              isScrolled={this.state.scrollY > 360}
              scrollPosition={this.state.scrollY}
              viewHeight={this.state.viewHeight}
              scrollTo={(identifier) => this.scrollTo(identifier)}
              language={this.state.language}
            />
          </div>

          {/* Highlights */}
          <div ref={this.ref__highlights}>
            <Highlights 
              highlights={this.props.highlights} 
              breakpoint={this.getBreakpoint(this.state.viewWidth)} 
              language={this.state.language}  
            />
          </div>
        </div>

        {/* Vision */}
        <div
          ref={this.ref__vision}
          className="app-wrapper--scrollable"
          style={{
            padding: `
              ${this.ref__preVision && this.state.scrollArea === 'vision' ? this.ref__preVision.current.clientHeight : 0}px
              0
              ${this.ref__postVision && this.state.scrollArea === 'vision' ? this.ref__postVision.current.clientHeight : 0}px`
            }}>

          {/* Vision */}
          <Vision
            positionY={(this.state.scrollY - this.state.scrollAreaBoundaries.pre) / (this.state.scrollAreaBoundaries.post - this.state.scrollAreaBoundaries.pre)}
            scrollArea={this.state.scrollArea}
            contentsHeight={this.state.visionContentsHeight}
            containerHeight={this.state.visionContainerHeight}
            breakpoint={this.getBreakpoint(this.state.viewWidth)}
            offset={this.getOffsetInVisionMode()}
          />
        </div>

        {/* After Vision */}
        <div
          ref={this.ref__postVision}
          className={`app-wrapper--post-scrollable ${this.state.scrollArea}`}
          style={{
            top: this.state.scrollArea === 'vision'
            ? this.getPostVisionTopInVisionMode()
            : ( this.state.scrollArea === 'pre' ? this.state.visionContentsHeight - this.state.visionContainerHeight : 0 ) }}>

          {/* Projects */}
          <div ref={this.ref__projects}>
            <Projects />
          </div>

          {/* Publications */}
          <div ref={this.ref__publications} name="scroll-element__publications">
            <Publications
              publications={this.props.publications}
              breakpoint={this.getBreakpoint(this.state.viewWidth)}
              language={this.state.language}
            />
          </div>

          {/* Our Lab */}
          <div ref={this.ref__our_lab} name="scroll-element__our-lab">
            <Members
              members={this.props.members}
              language={this.state.language}
            />
          </div>
          
          {/* Gallery */}
          <div ref={this.ref__gallery}>
            <Gallery
              gallery={this.props.gallery}
              breakpoint={this.getBreakpoint(this.state.viewWidth)}
            />
          </div>

          {/* Join Us */}
          <div ref={this.ref__join_us} name="scroll-element__join-us">
            <JoinUs
              openings={this.props.openings}
              language={this.state.language}
            />
          </div>

          {/* Contact */}
          <div ref={this.ref__contact} name="scroll-element__contact">
            <Contact 
              language={this.state.language}
            />
          </div>

          {/* Footer */}
          <Footer />
        </div>
      </div>
      <Loader isLoading={this.props.isLoading || !this.state.isAppReady} />
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
