export default {
  translation: {
    // Navigation
    NAVIGATION__TEAM_NAME : 'グリア-神経回路動態研究チーム',
    NAVIGATION__AFFILIATION: 'Jun Nagai Lab at RIKEN',
    NAVIGATION__ITEM__VISION: 'Vision',
    NAVIGATION__ITEM__PUBLICATIONS: 'Publications',
    NAVIGATION__ITEM__OUR_LAB: 'Our Lab',
    NAVIGATION__ITEM__JOIN_US: 'Join Us',
    NAVIGATION__ITEM__CONTACT: 'Contact',
    NAVIGATION__ITEM__LANGUAGE: 'English',

    // Header
    HEADER__TAGLINE: 'アストロサイト: \n内なる宇宙の星型細胞',

    // Highlights
    HIGHLIGHTS__SECTION_TITLE: 'Highlights',

    // Vision
    VISION__SECTION_TITLE: 'Our Vision',
    VISION__DESCRIPTION_1: '内なる宇宙、脳では',
    VISION__DESCRIPTION_2: 'ニューロン（神経細胞）が回路を形作っています',
    VISION__DESCRIPTION_3: 'ニューロンに加え、私たちはアストロサイト（星形膠細胞）に注目し、',
    VISION__DESCRIPTION_4: '脳の機能発揮や疾患メカニズムのさらなる理解を目指しています。',

    // Projects
    PROJECTS__SECTION_TITLE: 'Research Topics',
    PROJECTS__SECTION_SUBTITLE: '当研究室では、アストロサイト機能について主に３つの疑問を投げかけ、研究を進めています',
    PROJECTS__PROJECT_1_TITLE: 'いつ・どこで・どのように脳回路を制御するか？',
    PROJECTS__PROJECT_1_DESCRIPTION: 'アストロサイトは無数に分岐した微細突起（先端~100 nm）を用いて、形態的にニューロンや他のグリアと密接に相互作用しています。一つのアストロサイトは何千～何万のシナプスを内包します。当研究室は、いつ・どこで・どのようにアストロサイトが生理学的に他細胞と連関し、脳回路機能を制御するかを調べています。',
    PROJECTS__PROJECT_2_TITLE: '動物の適応行動に関与するか？',
    PROJECTS__PROJECT_2_DESCRIPTION: '動物の生存のためには状況に応じた適応行動が必要であり、精神疾患ではそのような行動が不適応になっています。数多くの行動におけるアストロサイトの関与が報告されており、アストロサイト遺伝子の異常は、ヒト疾患を引き起こすことが知られています。私たちは、新規遺伝学ツールを用いて、適応行動におけるアストロサイトの役割を明らかにすることを目指しています。',
    PROJECTS__PROJECT_3_TITLE: 'どのように多様な機能を発揮するのか？',
    PROJECTS__PROJECT_3_DESCRIPTION: '私たちの脳にひしめくアストロサイトは、長らく均一な細胞群と考えられていました。ところが、脳の領域や状態によって多様な分子的特性を見せることが分かってきました。この分子的多様性がアストロサイトが多岐にわたる機能とどう関連するのか、大規模な遺伝子発現網羅解析を足掛かりに、その謎に迫ります。',

    // Publications
    PUBLICATIONS__SECTION_TITLE: 'Selected Publications',
    PUBLICATIONS__SEE_ALL: '論文リストをみる →',

    // Our Laboratory
    OUR_LAB__SECTION_TITLE: 'Our Laboratory',
    OUR_LAB__DOWNLOAD_CV: 'CVをダウンロード',

    // Join Us
    JOIN_US__SECTION_TITLE: 'Join Our Lab',
    JOIN_US__DESCRIPTION: '当研究室は2020年11月にスタートしました。遺伝学、分子生物学、生理学、イメージング、行動神経科学的手法を用いて、”脳の残り半分”であるグリア細胞の特性・機能に迫ります。意欲的な研究者、技官、学生を募集しています。フェローシップやグラント獲得のサポート致します。詳しくは、以下のリンクをご参照ください',

    // Contact
    CONTACT__VISIT_US: 'Visit Us',
    CONTACT__VISIT_ADDRESS: '〒351-0198 埼玉県和光市広沢2-1 中央研究棟S602',
    CONTACT__VISIT_DESCRIPTION: '和光市駅からタクシーで10分、39番（大泉学園行き）バスで15分、徒歩20分',
    CONTACT__CONTACT_US: 'Contact Us',
    CONTACT__CONTACT_TEL: '048-467-6896',
    CONTACT__CONTACT_EMAIL: 'jun.nagai[at]riken.jp',
    CONTACT__CONTACT_DESCRIPTION: '研究員、技官、学生の受け入れ、また共同研究についてお気軽にご連絡ください。',

    // Footer
    FOOTER__TEAM_NAME : 'グリア-神経回路動態研究チーム',
    FOOTER__AFFILIATION: 'Jun Nagai Lab at RIKEN Center for Brain Science (CBS)',
    FOOTER__COPYRIGHTS: `© Jun Nagai, ${new Date().getFullYear()} - all rights reserved.`
  }
}