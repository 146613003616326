import React from 'react';
import { useTranslation } from 'react-i18next';

import './style.scss';

const JoinUs = (props) => {
  const { openings, language } = props;
  const { t } = useTranslation();
  return (
    <div className="composition--join-us">
      <div className="container">
        <div className="grid-noGutter">
          <div className="col-6_sm-12 contents">
            <h4 className="header">{t('JOIN_US__SECTION_TITLE')}</h4>
            <p className="body">{t('JOIN_US__DESCRIPTION')}</p>
            <div className="backdrop" />
          </div>
          <div className="col-6_sm-12 postings">
            {/* <div className="img" /> */}
            {openings &&
              openings.map((opening, index) => {
                return (
                  <div key={`opening-container-${index}`} className="opening-container">
                     <a target="_blank" rel="noopener noreferrer" href={opening.fields?.link || '/#'}>
                       <h6 className="title">{language === 'ja' ? opening.fields?.title_ja : opening.fields?.title}</h6>
                    </a>
                    <p>{language === 'ja' ? opening.fields?.location_ja : opening.fields?.location}</p>
                  </div>
                );
              })
            }
          </div>
        </div>
        
      </div>
    </div>
  );
};

JoinUs.propTypes = {
  
}


export default JoinUs;
