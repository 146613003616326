import React from 'react';
import { withTranslation } from 'react-i18next';
import './style.scss';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileMenuOpen: false,
    };
  }

  render() {
    const { t, scrollPosition, viewHeight } = this.props;
    const yPos = scrollPosition < viewHeight ? (scrollPosition < 0 ? 0 : scrollPosition) : 0;
    return (
      <div className="composition--header">
        <div className="container">
          <div className="hero-tagline">
          {/* <div className="hero-tagline"> */}
            <h1 className="t" style={{
              paddingTop: `${yPos / 1.75}px`,
              opacity: 1 - yPos / (viewHeight / 2),
              filter: `blur(${yPos / 120}px)`,
              transform: `scale(${1 + yPos / 2500})`
            }}>{t('HEADER__TAGLINE')}</h1>  
          </div>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  
}


export default withTranslation()(Header);
